.btn,
.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  background: url(/images/btn-red.png) no-repeat !important;
  background-size: 100% 100% !important;
  font-size: tovw(26);
  text-transform: uppercase;
  color: #fff !important;
  height: tovw(78);
  line-height: tovw(78);
  padding: 0 tovw(15);
  outline: none !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  min-width: tovw(291);
  position: relative;
  font-weight: bold;
  transition: transform 0.25s ease;
  text-shadow: 0px tovw(4) tovw(4) rgba(109, 26, 6, 0.4);
  @include min-screen(1200px) {
    &:hover {
      transform: translateY(-0.5rem);
    }
  }
  &--purple {
    background: url(/images/btn-purple.png) no-repeat !important;
    background-size: 100% 100% !important;
  }
  &--yellow {
    background: url(/images/btn-yellow.png) no-repeat !important;
    background-size: 100% 100% !important;
    color: #b40000 !important;
    text-shadow: 0px tovw(4) tovw(4) rgba(255, 223, 46, 1);
  }
}
.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel  {
  min-width: tovw(200) !important;
  height: tovw(60) !important;
  line-height: tovw(60) !important;
}
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  @extend %grayscale;
}
