html {
  height: 100%;
  font-size: 62.5%;
}
body {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  font-size: tovw(18);
  color: #fff;
  font-family: $condensed;
  background-color: #000;
  &.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    height: 100% !important;
  }
}
img {
  max-width: 100% !important;
}
#root {
  position: relative;
  min-height: 100%;
}
button {
  outline: none !important;
}
a {
  color: #fff;
  transition: color 0.25s ease;
  outline: none !important;
  &:hover {
    color: #000;
    text-decoration: none;
  }
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 1rem;
  padding-right: 1rem;
}
.container {
  max-width: 100% !important;
  padding-left: 1rem;
  padding-right: 1rem;
  &--content {
    max-width: tovw(1500) !important;
  }
}
.row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.swal2-shown,
.ReactModal__Body--open {
  #root {
    //filter: blur(5px);
  }
}
.swal2-container.swal2-backdrop-show {
  background-color: rgba(0, 0, 0, 0.6) !important;
  overflow-y: auto !important;
  .swal2-popup {
    display: flex;
  }
}
.swal2-container {
  .swal2-popup {
    height: auto !important;
    width: tovw(568) !important;
    min-height: tovw(300) !important;
    // background: url(/images/popup.jpg) !important;
    // background-size: 100% 100% !important;
    background: #B8DFD2;
    background: -webkit-linear-gradient(to top, #B8DFD2 0%, #FFFFFF 100%);
    background: -moz-linear-gradient(to top, #B8DFD2 0%, #FFFFFF 100%);
    background: linear-gradient(to top, #B8DFD2 0%, #FFFFFF 100%);
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 1rem !important;
    box-shadow: 0 0 2rem #fff;
    .swal2-close,
    .close {
      position: absolute;
      width: 14px;
      height: 14px;
      // background: url(#{$cdnUrl}/images/close.png) no-repeat center;
      background-size: 100%;
      top: 10px;
      right: 10px;
      opacity: 1;
      font-size: 4rem;
      outline: none !important;
      color: #000 !important;
      font-family: serif;
      font-weight: 300;
      z-index: 9;
      text-indent: -9999px;
    }
    #swal2-content {
      font-size: tovw(20);
      color: #f3e1b9;
      line-height: 150%;
      text-transform: uppercase;
      p {
        font-size: tovw(20);
        color: #000;
        line-height: 150%;
        text-transform: uppercase;
      }
    }
    #swal2-title,
    h2 {
      font-size: tovw(30);
      font-weight: normal;
      text-align: center;
      text-transform: uppercase;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;
      color: #161349;
      justify-content: center;
      font-family: $font-heading;
    }
    &.popup-image {
      background: transparent !important;
      box-shadow: none !important;
      width: auto !important;
      padding: 0 !important;
      border-radius: 0.5vw !important;
      overflow: hidden;
      img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        backface-visibility: hidden;
      }
    }
  }
}
.ReactModal__Overlay {
  display: flex !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px !important;
  background: rgba(0, 0, 0, 0.8) !important;
  z-index: 1060 !important;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch !important;
}
.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  box-sizing: border-box;
  margin: auto;
  width: tovw(568) !important;
  min-height: tovw(300) !important;
  background: #B8DFD2;
  background: -webkit-linear-gradient(to top, #B8DFD2 0%, #FFFFFF 100%);
  background: -moz-linear-gradient(to top, #B8DFD2 0%, #FFFFFF 100%);
  background: linear-gradient(to top, #B8DFD2 0%, #FFFFFF 100%);
  border-radius: 1rem !important;
  box-shadow: 0 0 2rem #fff;
  border: 0 !important;
  padding: 2rem 0 !important;
  text-align: center;
  outline: none;
  .modal-description {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  &.modal-history {
    width: tovw(890) !important;
    min-height: tovw(530) !important;
    .modal-description {
      width: 94%;
    }
  }
  .close {
    position: absolute;
    width: 14px;
    height: 14px;
    // background: url(#{$cdnUrl}/images/close.png) no-repeat center;
    background-size: 100%;
    top: 10px;
    right: 10px;
    opacity: 1;
    font-size: 4rem;
    outline: none !important;
    color: #000 !important;
    font-family: serif;
    font-weight: 300;
    z-index: 9;
    text-indent: -9999px;
  }
  h2 {
    font-size: tovw(30);
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #161349;
    justify-content: center;
    font-family: $font-heading;
  }
  p,
  li {
    font-size: tovw(20);
    color: #000;
  }
}
#main-body {
  position: relative;
}
.main-content {
  position: relative;
}
.ScrollbarsCustom-Wrapper {
  right: 0 !important;
}
.ScrollbarsCustom-Track {
  width: tovw(10) !important;
  right: tovw(-35) !important;
  z-index: 1;
  background: transparent !important;
  &:before {
    content: "";
    position: absolute;
    width: 1px;
    background: #6d1a06;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }
  .modal-history & {
    right: 0 !important;
    &:before {
      background: #6d1a06;
    }
  }
}
.ScrollbarsCustom-Thumb {
  width: 100% !important;
  border-radius: 0.5vw !important;
  background: #6d1a06 !important;
  .modal-history & {
    background: #6d1a06 !important;
  }
}
#header {
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
#footer {
  position: relative;
  background: #000;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  .container {
    width: tovw(1320);
  }
  .footer-logo {
    display: inline-block;
    width: tovw(226);
  }
  .age {
    display: inline-block;
    width: tovw(142);
  }
  .col:nth-child(2) {
    flex: 0 0 tovw(910);
    max-width: tovw(910);
  }
  p {
    font-size: tovw(11);
    text-transform: uppercase;
    color: #6a6a6a;
    text-align: justify;
    margin-bottom: 0;
  }
}
.section {
  min-height: tovw(1058);
  background: url(/images/bg.jpg) no-repeat;
  background-size: 100% 100%;
  .col-5 {
    flex: 0 0 43.666667%;
    max-width: 43.666667%;
  }
  .col-7 {
    flex: 0 0 56.333333%;
    max-width: 56.333333%;
  }
}
.logo {
  display: block;
  width: tovw(350);
  margin: tovw(15) auto tovw(25) auto;
}
.title {
  width: tovw(565);
  margin: 0 auto;
}
.setting {
  display: block;
  font-size: tovw(45);
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  text-shadow: 0px tovw(4) tovw(4) rgba(109, 26, 6, 0.8);
  margin-top: tovw(20);
  text-align: center;
  transform: color .3s ease;
  img {
    width: tovw(37);
    transition: transform .3s ease;
    transform-origin: center;
    &.arrow-dow {
      transform: rotate(180deg);
    }
  }
  &:hover {
    color: #cf2806;
  }
}
.spin {
  position: relative;
  width: tovw(698);
  margin: tovw(120) auto 0 auto;
  &::before {
    content: '';
    position: absolute;
    bottom: tovw(-120);
    left: 50%;
    transform: translateX(-50%);
    width: tovw(515);
    height: tovw(156);
    background: url(/images/spin-dock-shadow.png) no-repeat;
    background-size: 100% 100%;
  }
  img {
    position: relative;
    z-index: 2;
  }
  &__arrow {
    width: tovw(66);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
  &__btn {
    width: tovw(172);
    position: absolute;
    top: tovw(266);
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
      width: tovw(132);
      z-index: 2;
      img {
        animation: tada 1.2s infinite ease;
      }
    }
  }
  &__change {
    position: absolute;
    bottom: tovw(-36);
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
  &__wheel {
    position: absolute;
    top: tovw(51);
    left: tovw(46);
    z-index: 2;
  }
}
.list-settings {
  width: 100%;
  border-bottom: 1px solid #6d1a06;
  border-top: 1px solid #6d1a06;
  padding: tovw(20) 0;
  margin-top: tovw(25);
  &__wrap {
    padding: 0 tovw(50);
  }
  &__item {
    display: flex;
    flex-wrap: wrap;
    font-size: tovw(18);
    font-weight: 500;
    color: #6d1a06;
    margin-bottom: tovw(20);
    align-items: center;
    text-align: center;
    &--index {
      width: 10%;
      color: #211a10;
      font-size: tovw(24);
      text-transform: uppercase;
    }
    &--name {
      width: 50%;
      padding: 0 tovw(10);
    }
    &--total,
    &--rate {
      width: 20%;
    }
  }
}
.settings-custom {
  &__type {
    text-align: center;
    padding-bottom: tovw(20);
    border-bottom: 1px solid #6d1a06;
    margin-bottom: tovw(25);
    a {
      font-size: tovw(24);
      color: #211a10;
      font-weight: 500;
      margin-right: tovw(40);
      img {
        width: tovw(46);
        margin-right: tovw(10);
      }
      &:last-child {
        margin-right: tovw(0);
      }
    }
  }
  &__form {
    padding-bottom: tovw(25);
    margin-bottom: tovw(25);
    border-bottom: 1px solid #6d1a06;
  }
  &__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: tovw(10);
    input {
      width: 100%;
      height: tovw(40);
      line-height: tovw(40);
      border: 0;
      outline: none;
      background: url(/images/input-bg.png) no-repeat;
      background-size: 100% 100%;
      color: #fff622;
      font-size: tovw(18);
    }
    p {
      margin-bottom: 0;
      font-size: tovw(14);
      color: #cf2806;
      position: absolute;
      left: 0;
      bottom: tovw(-17);
      width: 100%;
      text-align: center;
    }
    &--index {
      width: 6%;
      font-size: tovw(24);
      color: #211a10;
      font-weight: 500;
      text-transform: uppercase;
    }
    &--name {
      width: 30%;
      text-align: center;
      padding: 0 tovw(4);
      position: relative;
      input {
        text-align: center;
        padding: 0 tovw(15);
        // &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        //   color: #fff622;
        //   opacity: 1; /* Firefox */
        // }
        // &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        //   color: #fff622;
        // }
        // &::-ms-input-placeholder { /* Microsoft Edge */
        //   color: #fff622;
        // }
      }
    }
    &--total,
    &--rate {
      width: 22%;
      padding: 0 tovw(4);
      position: relative;
      input {
        background: url(/images/input-s-bg.png) no-repeat;
        background-size: 100% 100%;
      }
      label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #fff622;
        font-size: tovw(18);
        margin-bottom: 0;
      }
    }
    &--total {
      input {
        padding-left: tovw(96);
        padding-right: tovw(10);
      }
      label{
        left: tovw(40);
      }
    }
    &--rate {
      input {
        padding-left: tovw(80);
        padding-right: tovw(34);
      }
      label {
        left: tovw(50);
      }
      span {
        position: absolute;
        top: 50%;
        right: tovw(32);
        transform: translateY(-50%);
        color: #fff622;
        font-size: tovw(18);
        font-weight: 500;
      }
    }
    &--status {
      width: 16%;
      a {
        font-size: tovw(18);
        color: #211a10;
        padding-left: tovw(4);
      }
      img {
        width: tovw(37);
        margin-right: tovw(8);
      }
    }
    &--delete {
      cursor: pointer;
      width: 4%;
      font-size: tovw(30);
      color: #cf2806;
      text-align: center;
    }
  }
  &__total-rate {
    position: absolute;
    right: tovw(9);
    bottom: tovw(50);
    font-size: tovw(20);
    color: #211a10;
    text-transform: uppercase;
    span {
      color: #cf2806;
    }
  }
  &__actions {
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: tovw(-30);
    transform: translateX(-50%);
    width: 100%;
    .btn--yellow {
      margin-right: tovw(10);
    }
  }
}
.none {
  display: none;
}
.roulette {
  position: absolute;
  margin: 0 auto;
  width: 400px;
  height: 400px;
  left: 40%;
  background-color: white;
  // background-image: url('https://i.imgur.com/tM641Nk.png');
  border-radius: 300px;
}
canvas {
  position: "absolute";
  left: 0;
  right: 0;
  margin: "auto";
  top: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.name-reward {
  color: #cf2806;
}
